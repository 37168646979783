@import '~@platform/formiojs-react/dist/index.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body > #root {
    height: 100vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
}

body {
    margin: 0;
    font-size: 13px;
    padding-right: 0 !important;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body > #root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}
